module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returns/Refunds"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
        "testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing/Quality"])},
        "giveGet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give $10, Get $10"])},
        "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "becomeBrandSpecialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a Brand Specialist"])},
        "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
        "privacyAndSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy & Security"])},
        "textSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease."])},
        "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2024 Solle Naturals. All Rights Reserved."])}
      },
      "es": {
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolsos/Devoluciones"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reseñas"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas Frecuentes"])},
        "testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pruebas/Calidad"])},
        "giveGet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da $10, Recibe $10"])},
        "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta a Solle"])},
        "becomeBrandSpecialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conviértete en Especialista de Marca"])},
        "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos & Condiciones"])},
        "privacyAndSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidad & Seguridad"])},
        "textSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Estas declaraciones no han sido evaluadas por la Administración de Alimentos y Medicamentos. Estos productos no intentan diagnosticar, tratar, curar o prevenir ninguna enfermedad."])},
        "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2024 Solle Naturals. Derechos Reservados."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"return":"Returns/Refunds","review":"Reviews","faq":"FAQs","testing":"Testing/Quality","giveGet":"Give $10, Get $10","contactUs":"Contact Us","becomeBrandSpecialist":"Become a Brand Specialist","termsAndConditions":"Terms & Conditions","privacyAndSecurity":"Privacy & Security","textSection":"* These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.","rights":"©2024 Solle Naturals. All Rights Reserved."},"es":{"return":"Reembolsos/Devoluciones","review":"Reseñas","faq":"Preguntas Frecuentes","testing":"Pruebas/Calidad","giveGet":"Da $10, Recibe $10","contactUs":"Contacta a Solle","becomeBrandSpecialist":"Conviértete en Especialista de Marca","termsAndConditions":"Términos & Condiciones","privacyAndSecurity":"Privacidad & Seguridad","textSection":"* Estas declaraciones no han sido evaluadas por la Administración de Alimentos y Medicamentos. Estos productos no intentan diagnosticar, tratar, curar o prevenir ninguna enfermedad.","rights":"©2024 Solle Naturals. Derechos Reservados."}}')
  delete Component.options._Ctor
  
}
